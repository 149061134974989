<template>
  <div class="totalDrive">
    <p class="synopsis">驾驶型镜片</p>
    <img class="drive" src="../../assets/img/drive.png" alt="" @click="bf" />
    <div class="container">
      <div class="boxs" v-for="item in list" @click="jump(item)">
        <p class="title">{{item.title}}</p>
        <div class="content">
          <img :src="item.img" alt="">
          <p class="info">{{item.info}}</p>
        </div>
      </div>
    </div>
    <div class="btnDiv" style="display: none"><button class="retBtn" @click="go">返回</button></div>
    <el-dialog
        title=""
        class="sp"
        :visible.sync="showsp"
        width="80%"
        :destroy-on-close="true"
    >
      <video style="width: 100%" controls autoplay="autoplay">
        <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpVedio/jsxjp2.mp4" type="video/mp4">
        <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpVedio/jsxjp2.mp4" type="video/ogg">
        您的浏览器不支持Video标签。
      </video>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "totalDrive",
  data() {
    return {
      list: [],
      showsp: false
    }
  },
  created() {
    let arr = [{
      title: '减少眩光',
      img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_1.jpg',
      info: '详情',
      proId: 18
    },
    {
      title: '暗光条件下提供更好视力',
      img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg',
      info: '详情',
      proId: 19
    },
    {
      title: '精确观察道路 仪表盘 后视镜',
      img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_3.jpg',
      info: '详情',
      proId: 7
    }];
    this.list = arr;
  },
  methods: {
    bf() {
      this.showsp = true
    },
    jump(e) {
      this.$router.push({path: '/PopularScienceHome/proFunction', query: {
          proId: e.proId
        }})
    },
    go() {
      this.$router.go(-1);
    }
  }
}
</script>
<style scoped lang="scss">
  .synopsis {
    margin: 10px 0 0;
    font-size: 18px;
  }
  .drive {
    width: 32px;
    height: 32px;
  }
  .container {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
  .container .boxs {
    width: 20%;
  }
  .content {
    border: 1px solid #aaa;
  }
  .title {
    text-align: left;
    margin-bottom: 15px;
    padding: 0 5px;
    border-left: 1px solid #333;
  }
  .boxs img {
    width: 100%;
    margin: 0;
  }
  .info {
    margin: 5px auto;
  }
  .btnDiv {
    margin: 250px auto 0;
    display: flex;
    justify-content: center;
  }
  .retBtn {
    padding: 10px 80px;
    border: 1px solid #333;
    color: #333;
    font-size: 18px;
    background: transparent;
  }
  .sp {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  ::v-deep .sp .el-dialog {
    margin: 5vh !important;
    height: 90vh;
  //margin-top: 10vh;
  }
</style>
